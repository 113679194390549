<template>
  <v-app :class="{ landing: $route.name === 'landing', 'site-background': $route.name !== 'landing' }">
    <a href="#main-content" class="skip-link">Skip to main content</a>
    <v-container fluid>
      <v-main id="main-content" role="main" aria-label="Main content">
        <router-view />
        <v-snackbar v-model="snackbar.show" bottom role="alert" aria-live="polite">{{ snackbar.message }}</v-snackbar>
      </v-main>
    </v-container>
  </v-app>
</template>

<script>
import Vue from 'vue';

export default {
  name: "App",

  data: () => ({
    snackbar: {
      position: "center",
      duration: 4000,
      message: null,
      show: false
    }
  }),
  created() {
    Vue.$eventBus.$on("notifyUser", this.notifyUser);
  },
  destroyed() {
    Vue.$eventBus.$off("notifyUser", this.notifyUser);
  },
  methods: {
    notifyUser: function (message) {
      this.snackbar.message = message;
      this.snackbar.show = true;
    }
  }
};
</script>

<style>
.skip-link {
  background: #020659;
  color: white;
  font-weight: bold;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.skip-link:focus {
  transform: translateY(0%);
  z-index: 1000;
}

/* Ensure proper focus visibility for all interactive elements */
:focus {
  outline: 2px solid #020659;
  outline-offset: 2px;
}

/* But remove default focus from items with explicit focus styles */
.focus-visible:focus {
  outline: none;
}
</style>