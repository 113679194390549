<template>
  <v-card elevation="12" style="background: white !important; width: 100%" class="pa-4" role="main">
    <v-card-text>
      <h1 class="black--text mb-2">Sign In</h1>
      <v-form v-on:submit.prevent="loginSubmission" ref="loginForm" aria-label="Login form">
        <v-row>
          <v-col cols="12">
            <v-text-field label="Email" name="login" type="email" outlined dense :disabled="sending"
              v-validate.disable="'required|email'" :error-messages="errors.collect('Email')" data-vv-name="Email"
              required v-model="email" aria-label="Email address"
              :aria-describedby="errors.has('Email') ? 'email-error' : undefined" aria-required="true"></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field id="password" label="Password" name="password" outlined dense
              :type="showPassword ? 'text' : 'password'" :disabled="sending"
              :error-messages="errors.collect('Password')" v-validate="'required'" data-vv-name="Password" required
              v-model="password" append-icon="mdi-eye" @click:append="togglePassword" aria-label="Password"
              :aria-describedby="errors.has('Password') ? 'password-error' : undefined"
              aria-required="true"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="login-action-row">
          <v-col cols="6" class="text-left">
            <v-btn :disabled="sending" type="submit" :loading="sending" class="main-btn focus-visible"
              aria-label="Sign in to your account">
              Login
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <router-link class="forgot-link focus-visible" to="/auth/forgotPassword"
              aria-label="Reset your password">Forgot Password?</router-link>
          </v-col>
        </v-row>
      </v-form>
      <hr class="mt-4 mb-4" />
      <v-row>
        <v-col cols="12">
          <h2 class="black--text">Sign Up</h2>
        </v-col>
        <v-col cols="12">
          <span class="black--text">
            Impact HQ supports nonprofits serving 9 counties throughout WNY.
            Check to see if your organization is eligible for Impact-HQ.
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn to="/auth/registration" class="main-btn focus-visible" aria-label="Create a new account">Create an
            Account</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped lang="scss">
.focus-visible:focus {
  outline: 2px solid #020659;
  outline-offset: 2px;
}

.v-btn.focus-visible:focus::before {
  opacity: 0.08;
}

@import "~vuetify/src/styles/settings/_variables";

h3.tagline {
  font-size: 35px;
  font-weight: 500;
  color: #171b5b;
  margin: 35px 0;
}

.form-pinch {
  padding: 10% 10% 0 10%;
}

.row.login-action-row {
  margin-top: -30px;
}

.forgot-link {
  color: black;
  text-decoration: none;
  display: block;
  position: relative;
  top: -18px;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .no-left-pad-md {
    padding-left: 0;
  }
}
</style>

<script>
import Vue from "vue";
import logo from "../../assets/images/logo.png";

export default {
  name: "Login",
  beforeRouteEnter(to, from, next) {
    if (to.query.emailConfirmed) {
      Vue.$eventBus.$emit("notifyUser", "Email Verified! Please Log In");
    }
    next();
  },
  data() {
    return {
      email: null,
      password: null,
      sending: false,
      logo: logo,
      showPassword: false,
    };
  },
  methods: {
    togglePassword() {
      this.showPassword = !this.showPassword;
      const action = this.showPassword ? 'show' : 'hide';
      this.$announcer.announce(`Password ${action}n`, 'polite');
    },

    async loginSubmission() {
      let result;
      try {
        result = await this.$validator.validateAll();
        if (!result) {
          return result;
        }
      } catch (err) {
        return;
      }

      if (!result) {
        Vue.$eventBus.$emit("notifyUser", "Please fix the invalid fields");
        this.$announcer.announce("Form validation failed. Please check the highlighted fields.", "assertive");
        return false;
      }

      this.sending = true;
      try {
        await Vue.$userService.login(this.email, this.password);
        this.$router.push("/dashboard");
      } catch (err) {
        this.sending = false;
        this.$announcer.announce("Login failed. Please check your credentials and try again.", "assertive");
      }
    },
  },
};
</script>