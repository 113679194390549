// grantForm  service
export default {
  install: (Vue) => {
    //private
    const route = process.env.VUE_APP_API_BASE_URL + '/api/r-grant-reports';

    //public
    Vue.$grantReportFormService = {
      find(params) {
        return Vue.axios.get(route, {
          params: params
        });
      }
    };
  }
};