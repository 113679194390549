<template>
  <v-card elevation="12" style="background: white !important;" class="pa-4 black--text">
    <v-card-title>
      Verification Complete
    </v-card-title>
    <v-card-text>
      Your account has been verified. You can now log in to your account.
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :to="landingPageUrl" class="main-btn">Log In</v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped lang="scss">
@import "~vuetify/src/styles/settings/_variables";

h3.tagline {
  font-size: 35px;
  font-weight: 500;
  color: #171b5b;
  margin: 35px 0;
}

.form-pinch {
  padding: 10% 10% 0 10%;
}

@media #{map-get($display-breakpoints, 'md-and-up')} {
  .no-left-pad-md {
    padding-left: 0;
  }
}
</style>

<script>
import logo from "../../assets/images/logo.png";

export default {
  name: "completion",
  data() {
    return {
      logo: logo,
      landingPageUrl: process.env.VUE_APP_LANDING_PAGE_URL
    };
  },
};
</script>
