// grants service

export default {
  install: (Vue) => {
    //private
    const route = process.env.VUE_APP_API_BASE_URL + '/api/leadership-programs';

    //public
    Vue.$leadershipProgramService = {
      find(params) {
        return Vue.axios.get(route, {
          params: params
        });
      },

      findOne(key, params) {
        return Vue.axios.get(`${route}/${key}`, {
          params: params
        });
      },
    };
  }
};